export function getIgnoreValues(){
    return [
        "mailto",
        "javascript",
        "facebook.com",
        "twitter.com",
        "whatsapp.com",
        "linkedin.com",
        "cookiedatabase.org",
        "youtube.com",
      ];
}

export function getWhiteLinkDomains() {
    return [
        ".edu",
        ".gov",
        ".ac",
        ".org",
        "bbc.co.uk",
        "bbc.com",
        "msn.com",
        "cnn.com",
        "nytimes.com",
        "dailymail.co.uk",
        "news.google.com",
        "theguardian.com",
        "foxnews.com",
        "news.yahoo.com",
        "finance.yahoo.com",
        "nypost.com",
        "timesofindia.com",
        "thesun.co.uk",
        "the-sun.com",
        "indiatimes.com",
        "news18.com",
        "hindustantimes.com",
        "washingtonpost.com",
        "people.com",
        "cnbc.com",
        "mirror.co.uk",
        "usatoday.com",
        "india.com",
        "ndtv.com",
        "forbes.com",
        "rt.com",
        "independent.co.uk",
        "indianexpress.com",
        "buzzfeed.com",
        "businessinsider.com",
        "wsj.com",
        "reuters.com",
        "news.com.au",
        "indiatoday.in",
        "nbcnews.com",
        "apnews.com",
        "express.co.uk",
        "abc.net.au",
        "telegraph.co.uk",
        "cbsnews.com",
        "news.sky.com",
        "bloomberg.com",
        "cosmopolitan.com",
        "newsnow.co.uk",
        "cbc.ca",
        "huffpost.com",
        "politico.com",
        "timesnownews.com",
        "thehill.com",
        "hellomagazine.com",
        "usnews.com",
      ];
}