const isProduction = window.location.hostname.includes("vertical-leap.tools");
const clientContentApiUrl = isProduction
  ? "https://client-content-creator-api.vertical-leap.tools"
  : "https://localhost:7158";
const docGeneratorUrl = isProduction
  ? "https://jackoleary.pythonanywhere.com"
  : clientContentApiUrl;

export const getSerpInformation = async (query, headers) => {
  const url = `${clientContentApiUrl}/serp-results/${query}`;
  return await makeRequest(url, { headers });
};

export const getCrawlInformation = async (
  url,
  count,
  headers,
  timeoutDuration = 12000000
) => {
  const fetchUrl = `${clientContentApiUrl}/crawler/?url=${encodeURIComponent(
    url
  )}&count=${count}`;
  return await makeTimedRequest(fetchUrl, { headers }, timeoutDuration);
};

export const callGPTBriefSummary = async (combinedString, headers) => {
  const url = `${clientContentApiUrl}/call_gpt_brief_summary?headings=${encodeURIComponent(
    JSON.stringify(combinedString)
  )}`;

  try {
    const response = await fetch(url, { headers });
    if (!response.ok) throw new Error("Failed to fetch GPT summary");

    return await response.json();
  } catch (error) {
    console.error("Error fetching GPT summary:", error);
    return "Error, try again or enter this manually.";
  }
};

export const getContentBrief = async (allBriefData, headers) => {
  try {
    const response = await fetch(`${docGeneratorUrl}/generate_brief`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(allBriefData),
    });
    return response.ok ? await response.json() : null;
  } catch (error) {
    console.error("Error generating content brief:", error);
    return null;
  }
};

const makeRequest = async (url, options) => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

const makeTimedRequest = async (url, options, timeoutDuration) => {
  const controller = new AbortController();
  const timeout = setTimeout(() => {
    controller.abort(); // Abort if the request takes too long
  }, timeoutDuration);

  try {
    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(timeout); // Clear the timeout if the request completes

    if (!response.ok) {
      throw new Error(`Network response was not ok for URL: ${url}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
